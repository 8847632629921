import { useState, useRef } from "react";
import logo from "./Assets/logo-oyc.png";
import github from "./Assets/Octocat.jpg";
import linkedin from "./Assets/linkedin.png";
import books from "./Assets/books.jpg";
import code from "./Assets/code.jpg";
import "./App.css";

function App() {
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef(null);

  const handleMouseOver = (e, item) => {
    // console.log("className 👉️", item);
    setIsHovering(item);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://gloriang.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} className="logo" alt="logo" />
          <h1>Gloria Ng</h1>
        </a>
        <div className="social-section">
          <a
            className="App-link"
            href="https://linkedin.com/in/fengshuigal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} className="linkedin icon" alt="linkedin" />
          </a>
          <a
            className="App-link"
            href="https://github.com/codexmon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} className="github icon" alt="github" />
          </a>
        </div>
      </header>
      <section className="banner-section">
        <a
          ref={ref}
          className="App-link books"
          href="https://arts.gloriang.com"
          target="_blank"
          rel="noopener noreferrer"
          onMouseOver={(e) => handleMouseOver(e, "Books")}
          onMouseOut={handleMouseOut}
          onTouchStart={(e) => handleMouseOver(e, "Books")}
          onTouchEnd={handleMouseOut}
          style={{backgroundColor: isHovering ? "rgba(0,0,0,.6)" : "rgba(0,0,0,1)"}}
        >
          <div>
            <img src={books} className="books" alt="books" />
            {isHovering === "Books" && <h2>Books</h2>}
          </div>
        </a>
        <a
          ref={ref}
          className="App-link dev"
          href="https://git.gloriang.com"
          target="_blank"
          rel="noopener noreferrer"
          onMouseOver={(e) => handleMouseOver(e, "Tech")}
          onMouseOut={handleMouseOut}
          onTouchStart={(e) => handleMouseOver(e, "Tech")}
          onTouchEnd={handleMouseOut}
          style={{backgroundColor: isHovering ? "rgba(0,0,0,.6)" : "rgba(0,0,0,1)"}}
        >
          <div>
            <img src={code} className="code" alt="code" />
            {isHovering === "Tech" && <h2>Tech</h2>}
          </div>
        </a>
      </section>
      <footer>© 2022. Gloria Ng. All rights reserved.</footer>
    </div>
  );
}

export default App;
